<template>
  <div class="login-form-page">
    <h1>ebatte SaaS</h1>
    <a-form
        id="components-form-demo-normal-login"
        :form="form"
        class="login-form"
        @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
            v-decorator="[
            'userName',
            { rules: [{ required: true, message: '请输入用户名！' }] },
          ]"
            placeholder="用户名"
        >
          <a-icon slot="prefix" type="user" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
        <a-input
            v-decorator="[
            'password',
            { rules: [{ required: true, message: '请输入密码！' }] },
          ]"
            type="password"
            placeholder="密码"
        >
          <a-icon slot="prefix" type="lock" style="color: rgba(0,0,0,.25)" />
        </a-input>
      </a-form-item>
      <a-form-item>
  <!--      <a-checkbox-->
  <!--          v-decorator="[-->
  <!--          'remember',-->
  <!--          {-->
  <!--            valuePropName: 'checked',-->
  <!--            initialValue: true,-->
  <!--          },-->
  <!--        ]"-->
  <!--      >-->
  <!--        记住我-->
  <!--      </a-checkbox>-->
  <!--      <a class="login-form-forgot" href="">-->
  <!--        忘记密码-->
  <!--      </a>-->
        <a-button type="primary" html-type="submit" class="login-form-button">
          登录
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: "LoginPage",
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'normal_login' });
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log('Received values of form: ', values);
          const { userName, password } = values
          if (userName === 'customer_65086' && password === 'HkUps8076@2021') {
            this.$emit('login', 'B65086')
          } else if (userName === 'customer_60000' && password === 'HkUps8076@2021') {
            this.$emit('login', 'B00000')
          } else {
            alert("用户名或密码错误！")
          }
        }
      });
    },
  },
}
</script>

<style scoped>

#components-form-demo-normal-login .login-form {
  max-width: 300px;
}
#components-form-demo-normal-login .login-form-forgot {
  float: right;
}
#components-form-demo-normal-login .login-form-button {
  width: 100%;
}
.login-form-page {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  padding: 20px;
  //background-color: #f3f3f3;
  background-color: #f0f2f5;
}

.login-form {
  width: 100%;
  max-width: 300px;
}
</style>
