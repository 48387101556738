<template>
  <div>
    <VChart
        :option="getOptions({
          title,
          lineColor: mainColor,
          yAxisName: title,
          data: graphData,
        })"
        :autoresize="true"
    />
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer, SVGRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  SVGRenderer,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: "LineGraph",
  components: { VChart },
  props: {
    title: { type: String, default: '' },
    mainColor: { type: String, default: '#7eaef3' },
    sliderFillerColor: { type: String, default: 'rgba(167,183,204,0.4)' },
    isGradient: { type: Boolean, default: false },
    graphData: { type: Object },
  },
  methods: {
    getRandomData() {
      let base = +new Date(2021, 6, 3);
      const oneDay = 24 * 3600 * 1000;
      const date = [];

      const data = [Math.random() * 30];

      for (let i = 1; i < 30; i++) {
        const now = new Date(base += oneDay);
        date.push([now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'));
        data.push(Math.round((Math.random() - 0.5) * 20 + data[i - 1]));
      }
      return { time: date, data }
    },
    getOptions({ title="图表", yAxisName="", lineColor="#7eaef3", data=this.getRandomData() } = {}) {
      const {time: timeData, data: valueData} = data
      const finalData = []
      for (let i = 0; i < timeData.length; i++) {
        finalData.push([new Date(timeData[i]), valueData[i]])
      }
      // return {
      //   tooltip: {
      //     trigger: 'axis',
      //     position: function (pt) {
      //       return [pt[0], '10%'];
      //     }
      //   },
      //   // gird: [{
      //   //   left: 0,
      //   //   right: 0,
      //   //   bottom: 0,
      //   //   top: 0,
      //   // }],
      //   title: {
      //     left: 'center',
      //     text: '大数据量面积图',
      //     show: false,
      //     height: 0,
      //   },
      //   // toolbox: {
      //   //     feature: {
      //   //         dataZoom: {
      //   //             yAxisIndex: 'none'
      //   //         },
      //   //         restore: {},
      //   //         saveAsImage: {}
      //   //     }
      //   // },
      //   xAxis: [{
      //     type: 'category',
      //     boundaryGap: false,
      //     data: date
      //   }],
      //   yAxis: [{
      //     type: 'value',
      //     boundaryGap: [0, '100%']
      //   }],
      //   dataZoom: [{
      //     type: 'inside',
      //     start: 0,
      //     end: 100
      //   }],
      //   series: [
      //     {
      //       name: title,
      //       type: 'line',
      //       symbol: 'none',
      //       sampling: 'lttb',
      //       itemStyle: {
      //         color: lineColor
      //       },
      //       // areaStyle: {
      //       //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
      //       //         offset: 0,
      //       //         color: 'rgb(255, 158, 68)'
      //       //     }, {
      //       //         offset: 1,
      //       //         color: 'rgb(255, 70, 131)'
      //       //     }])
      //       // },
      //       data: data
      //     }
      //   ]
      // }
      const visualMap = this.isGradient ? [{
        show: false,
        type: 'continuous',
        seriesIndex: 0,
        min: 0,
        max: 50,
        inRange: {
          color: [
            '#37cae0',
            // 'rgb(203,203,80)',
            'rgb(255,85,85)',
          ],
          // symbolSize: [30, 100]
        }
      }] : null

      return {
        visualMap,
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          },
        },
        title: {
          left: 'center',
          text: title,
          show: false,
          height: 0,
        },
        dataZoom: [
          {
            show: true,
            realtime: true,
            // start: 0,
            // end: 100,
            fillerColor: this.sliderFillerColor
          },
        ],
        grid: [{
          left: 60,
          right: 40,
          top: yAxisName ? 30 : 10,
          // height: '35%'
        }],
        xAxis: [
          {
            // type: 'category',
            type: 'time',
            boundaryGap: false,
            axisLine: {onZero: true},
            // data: timeData
          },
        ],
        yAxis: [
          {
            // boundaryGap: [0, '100%'],
            name: yAxisName,
            type: 'value',
          },
        ],
        series: [
          {
            name: yAxisName,
            type: 'line',
            // symbol: 'none',
            symbolSize: 8,
            sampling: 'lttb',
            itemStyle: {
              color: lineColor
            },
            // data: valueData
            data: finalData
          },
        ]
      };
    }
  }
}
</script>

<style scoped>

</style>
