<template>
  <a-card
      title="溫度"
  >
    <div slot="extra">
      <DateSelection @change="handleDateChanged" />
    </div>
    <LineGraph
        class="graph"
        :title="'溫度'"
        :is-gradient="true"
        :graph-data="graphData"
    />
  </a-card>
</template>

<script>
import LineGraph from "@/components/LineGraph";
import DateSelection from "@/components/DateSelection";
import {loadHistoryMixinFactory} from "@/mixins/load-history-mixin";
export default {
  name: "TempCard",
  mixins: [loadHistoryMixinFactory('htemper')],
  components: {LineGraph, DateSelection},
  data() {
    return {}
  },
  methods: {
    onTabChange(key) {
      this.nowTab = key
    }
  }
}
</script>

<style scoped>
.graph {
  height: 200px;
}
</style>
