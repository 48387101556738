<template>
<div class="date-selector--container">
<!--  <div class="date-select-group">-->
  <div class="date-link sm-hide" :class="{now: selected === 'day'}" @click="onSelectDay">
    今日
  </div>
  <div class="date-link sm-hide" :class="{now: selected === 'week'}" @click="onSelectWeek">
    本周
  </div>
  <div class="date-link sm-hide" :class="{now: selected === 'month'}" @click="onSelectMonth">
    本月
  </div>
  <div class="date-link sm-hide" :class="{now: selected === 'year'}" @click="onSelectYear">
    全年
  </div>
  <div class="divide sm-hide" />
  <a-popover title="选择时间段">
    <template slot="content">
      <a-range-picker :value="range" @change="onSelectCustom" />
    </template>
    <div class="date-link" :class="{now: selected === 'custom'}">
      自定義
    </div>
  </a-popover>
<!--  </div>-->
<!--  <div class="divide" />-->
<!--  <a-range-picker />-->
<!--  <a-date-picker />-->
<!--  <a-date-picker />-->
</div>
</template>

<script>
import moment from 'moment'

export default {
  name: "DateSelection",
  data() {
    return {
      selected: 'day',
      range: [],
    }
  },
  methods: {
    toStrObj(dates) {
      const start = dates[0].format('YYYY-M-D')
      const end = dates[1].format('YYYY-M-D')
      return {start, end}
    },
    onSelectDay() {
      this.selected = 'day'
      const now = moment();
      const before = moment(now);
      before.add(-1, 'days')
      const moments = [before, now]
      this.range = moments
      this.$emit("change", this.toStrObj(moments))
    },
    onSelectWeek() {
      this.selected = 'week'
      const now = moment();
      const before = moment(now);
      before.add(-1, 'weeks')
      const moments = [before, now]
      this.range = moments
      this.$emit("change", this.toStrObj(moments))
    },
    onSelectMonth() {
      this.selected = 'month'
      const now = moment();
      const before = moment(now);
      before.add(-1, 'months')
      const moments = [before, now]
      this.range = moments
      this.$emit("change", this.toStrObj(moments))
    },
    onSelectYear() {
      this.selected = 'year'
      const now = moment();
      const before = moment(now);
      before.add(-1, 'years')
      const moments = [before, now]
      this.range = moments
      this.$emit("change", this.toStrObj(moments))
    },
    onSelectCustom(dates) {
      this.selected = 'custom'
      this.range = dates
      this.$emit("change", this.toStrObj(dates))
      // debugger
      // console.log(dates)
    },
  },
}
</script>

<style scoped>
.date-selector--container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.date-select-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.date-link {
  margin: 0 6px;
  user-select: none;
  cursor: pointer;
  font-size: 12px;
}

.date-link.now {
  color: #21b9ff;
}

.divide {
  width: 1px;
  height: 20px;
  background-color: #eee;
  margin: 5px;
}

.date-link.custom {
  color: #333;
}

.date-link.select {
  color: #21b9ff;
}

@media screen and (max-width: 768px) {
  .sm-hide {
    display: none;
  }
}

</style>
