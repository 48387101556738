import axios from "axios";
import moment from "moment";

const DEFAULT_PACK_ID = 'B00000'
// const DEFAULT_PACK_ID = 'B65086'

// const DEFAULT_DATE = '2021-06-08'
const DEFAULT_DATE = moment().format('YYYY-M-D')

export const client = axios.create({
  // baseURL: 'https://test.ebatte.com/api/upsservice/v1/',
  baseURL: 'https://customer-e65086.ebatte.com/api/upsservice/v1/',
  timeout: 3000,
});

export function verifyResponse(res) {
  const {data, status} = res.data
  if (status !== 0) {
    console.error('[network] status code err: ', status, res)
    throw new Error(`[network] status code err: ${status}`)
  }
  return data
}

export function getStatusData({packId = DEFAULT_PACK_ID} = {}) {
  return client.get(`status`, {
    params: {pack_id: packId}
  })
}

export function getHistoryData({
  key,
  packId = DEFAULT_PACK_ID,
  start = DEFAULT_DATE,
  end = DEFAULT_DATE,
} = {}) {
  return client.get(`history`, {
    params: {
      pack_id: packId,
      key, start, end
    }
  })
}
