<template>
  <a-card
      :tab-list="tabs"
      :active-tab-key="nowTab"
      @tabChange="onTabChange"
  >
    <div slot="tabBarExtraContent">
      <DateSelection @change="handleDateChanged" />
    </div>
    <LineGraph
        class="graph"
        :title="this.tabs.find(obj => obj.key === this.nowTab).tab"
        :graph-data="graphData"
    />
  </a-card>
</template>

<script>
import LineGraph from "@/components/LineGraph";
import DateSelection from "@/components/DateSelection";
import {loadHistoryMixinFactory} from "@/mixins/load-history-mixin";
export default {
  name: "VoltCard",
  mixins: [loadHistoryMixinFactory('remainele')],
  components: {DateSelection, LineGraph},
  data() {
    return {
      nowTab: 'remainele',
      tabs: [
        {
          key: 'remainele',
          tab: '預估電量',
        },
        {
          key: 'tvoltage',
          tab: '電壓',
        },
      ]
    }
  },
  methods: {
    onTabChange(key) {
      this.nowTab = key
      this.graphKey = key
    }
  }
}
</script>

<style scoped>
.graph {
  height: 200px;
}
</style>
