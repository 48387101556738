<template>
  <a-card title="集群預覽">
    <div slot="extra">
      <a-popover title="運維信息">
        <template slot="content">
          <div class="maintain-container">
            <a-row :gutter="20"><a-col class="gutter-row" :xs="24" :md="12">
              <div class="gutter-box">
                <div class="argument-line">
                  <h5 class="argument-tile">最高電芯電壓：</h5>
                  <h1 class="argument-value">{{data.hvoltage || 0}}V</h1>
                  <p class="argument-status"
                     :class="getNormalClass(data.hvoltage_status)"
                  >{{getNormalText(data.hvoltage_status)}}</p>
                </div>
                <div class="argument-line">
                  <h5 class="argument-tile">最低電芯電壓：</h5>
                  <h1 class="argument-value">{{data.lvoltage || 0}}V</h1>
                  <p class="argument-status"
                     :class="getNormalClass(data.lvoltage_status)"
                  >{{getNormalText(data.lvoltage_status)}}</p>
                </div>
                <div class="argument-line">
                  <h5 class="argument-tile">充電控製：</h5>
                  <h1 class="argument-value">{{ data.ccontrol || '未知' }}</h1>
                </div>
                <div class="argument-line">
                  <h5 class="argument-tile">放電控製：</h5>
                  <h1 class="argument-value">{{ data.dcontrol || '未知' }}</h1>
                </div>
                <div class="argument-line">
                  <h5 class="argument-tile">最高電芯溫度：</h5>
                  <h1 class="argument-value">{{ data.htemper || 0 }}°C</h1>
                  <p class="argument-status"
                     :class="getNormalClass(data.htemper_status)"
                  >{{getNormalText(data.htemper_status)}}</p>
                </div>
                <div class="argument-line">
                  <h5 class="argument-tile">最低電芯溫度：</h5>
                  <h1 class="argument-value">{{ data.ltemper || 0 }}°C</h1>
                  <p class="argument-status"
                     :class="getNormalClass(data.ltemper_status)"
                  >{{getNormalText(data.ltemper_status)}}</p>
                </div>
              </div>
            </a-col>
              <a-col class="gutter-row" :xs="24" :md="12">
                <div class="gutter-box">
                  <div class="argument-line">
                    <h5 class="argument-tile">最高BMS溫度：</h5>
                    <h1 class="argument-value">{{ data.hbictemper || 0 }}°C</h1>
                    <p class="argument-status"
                       :class="getNormalClass(data.hbictemper_status)"
                    >
                      {{getNormalText(data.hbictemper_status)}}
                    </p>
                  </div>
                  <div class="argument-line">
                    <h5 class="argument-tile">BMC溫度：</h5>
                    <h1 class="argument-value">{{ data.bmctemper || 0 }}°C</h1>
                    <p class="argument-status"
                       :class="getNormalClass(data.bmctemper_status)"
                    >{{getNormalText(data.bmctemper_status)}}</p>
                  </div>
                  <div class="argument-line">
                    <h5 class="argument-tile">BMS總線通訊：</h5>
                    <h1 class="argument-value">{{ data.biccommun || '未知' }}</h1>
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </template>
        <a-icon type="info-circle" />
      </a-popover>
    </div>
    <a-row :gutter="20">
      <a-col class="gutter-row" :sm="24" :md="12" >
        <div class="gutter-box">
          <GaugeGraph class="gauge-graph" :num="data.remainele || 0" />
        </div>
      </a-col>
      <a-col class="gutter-row gap" :xs="24" :sm="0" :md="1" />
      <a-col class="gutter-row" :xs="12" :sm="12" :md="6">
        <div class="gutter-box">
          <a-row :gutter="4">
            <h5 class="comment-title">电流</h5>
            <h1 class="mention-value">{{data.current || 0 }} A</h1>
          </a-row>
          <a-row :gutter="4">
            <h5 class="comment-title">功率</h5>
            <h1 class="mention-value">{{data.power || 0 }} W</h1>
          </a-row>
          <a-row :gutter="4">
            <h5 class="comment-title">总电压</h5>
            <h1 class="mention-value">{{data.tvoltage || 0 }} V</h1>
          </a-row>
        </div>
      </a-col>
      <a-col class="gutter-row" :xs="12" :sm="12" :md="5">
        <div class="gutter-box">
          <a-row :gutter="4">
            <h5 class="comment-title">状态</h5>
            <h1 class="mention-value">{{getStatusText(data.direction)}}</h1>
          </a-row>
          <a-row :gutter="4">
            <h5 class="comment-title">温度</h5>
            <h1 class="mention-value">{{data.htemper || 0 }}°C</h1>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </a-card>
</template>

<script>

import GaugeGraph from "@/components/GaugeGraph";
export default {
  name: "UpsBaseCard",
  components: {GaugeGraph},
  props: {
    data: { type: Object, default: () => ({}) }
  },
  methods: {
    getStatusText(code) {
      if (!code) return '待机'
      if (code > 0) return '充电'
      if (code < 0) return '放电'
      return '未知'
    },
    getNormalText(code) {
      return code === 1 ? '正常' : '异常'
    },
    getNormalClass(code) {
      return {
        'color-normal': code === 1,
        'color-error': code === 0,
      }
    }
  }
}
</script>

<style scoped>
.gauge-graph {
  min-height: 200px;
}

.comment-title {
  color: #999;
  font-size: 12px;
  margin-bottom: 0;
}

.mention-value {
  font-size: 24px;
}

.info {
  padding-left: 60px;
}

.maintain-container {
  min-width: 550px;
  padding: 15px;
}

.argument-line {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}

.argument-tile {
  margin-bottom: 0;
  width: 100px;
}

.argument-value {
  margin-bottom: 0;
  position: relative;
  top: 4px;
  font-size: 20px;
  width: 80px;
  //text-align: right;
}

.argument-status {
  margin-bottom: 0;
  margin-left: 20px;
}

.argument-status.color-normal {
  color: green;
}

.argument-status.color-error {
  color: red;
}

@media screen and (max-width: 768px) {
  .gap {
    height: 50px;
  }

  .maintain-container {
    //width: 300px;
    min-width: 0;
  }
}
</style>
