<template>
  <VChart :option="getOptions(num)" :initOptions="getInitOptions()" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer, SVGRenderer } from "echarts/renderers";
import { GaugeChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
  CanvasRenderer,
  SVGRenderer,
  GaugeChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

export default {
  name: "GaugeGraph",
  props: {
    num: { type: Number, default: 0 },
  },
  components: {
    VChart
  },
  provide: {
    [THEME_KEY]: "light"
  },
  methods: {
    getInitOptions() {
      return {
        // renderer: "",
        // width: 1000,
        // height: 1000,
      }
    },
    getOptions(num) {
      return {
        gird: [{
          left: 0,
          right: 0,
          top: '55%',
          height: '35%'
        }],
        tooltip: {
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [{
          name: '電量狀況',
          type: 'gauge',
          progress: {
            show: true
          },
          detail: {
            valueAnimation: true,
            formatter: '{value}%'
          },

          radius: '110%',
          center: ['50%', '60%'],
          itemStyle: {
            color: '#8eae5a',
          },
          data: [{
            value: num,
            name: '預計電量'
          }]
        }]
      };
    }
  }
}
</script>

<style scoped>

</style>
