import {getHistoryData, verifyResponse} from "@/api/network";

const DEFAULT_REFRESH_TIME = 5 * 60 * 1000

export function loadHistoryMixinFactory(initKey, initPackId) {
  return {
    data() {
      return {
        graphData: {},
        refreshGraphHandler: null,
        timeRange: {},
        graphKey: initKey,
        graphPackId: initPackId,
      }
    },
    mounted() {
      this.updateGraphData()
      this.refreshGraphHandler = setInterval(() => {
        this.updateGraphData()
      }, DEFAULT_REFRESH_TIME)
    },
    watch: {
      timeRange() { this.updateGraphData() },
      graphKey() { this.updateGraphData() },
      graphPackId() { this.updateGraphData() },
    },
    methods: {
      async updateGraphData() {
        const { start, end } = this.timeRange
        const res = await getHistoryData({key: this.graphKey, packId: this.graphPackId, start, end})
        const data = verifyResponse(res)
        this.graphData = this.process2GraphData(data)
      },
      process2GraphData(raw) {
        const [time, data] = [[], []]
        raw.forEach(o => {
          time.push(o.created)
          data.push(o[this.graphKey])
        })
        return { time, data }
      },
      handleDateChanged(dates) {
        this.timeRange = dates
        // console.log('re', dates)
      }
    }
  }
}
