<template>
  <div id="app">
    <MainPage v-if="id" :id="id" />
    <LoginPage v-if="!id" @login="login" />
  </div>
</template>

<script>
import MainPage from "@/pages/MainPage";
import LoginPage from "@/pages/LoginPage";
import {LOGIN_STORAGE_KEY} from "@/consts";

export default {
  name: 'App',
  data() {
    return { id: localStorage.getItem(LOGIN_STORAGE_KEY) }
  },
  components: {
    LoginPage,
    MainPage,
  },
  methods: {
    login(id) {
      this.id = id
      localStorage.setItem(LOGIN_STORAGE_KEY, id)
    }
  },
}
</script>

<style>
#app {
  min-height: 100%;
  position: relative;
}
</style>
