<template>
  <div>
    <div class="logo">
      <img class="logo-img" src="../assets/logo.png" />
      <span class="logo-words">ebatte</span>
    </div>
    <a-menu theme="dark" mode="inline"
            :default-selected-keys="['ups']"
            :default-open-keys="['dashboard']"
    >
      <a-sub-menu key="dashboard">
        <span slot="title">
          <a-icon type="dashboard" /><span>儀表盤</span>
        </span>
        <a-menu-item key="ups">
<!--          <a-icon type="user" />-->
          <span>UPS</span>
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>

<script>
export default {
name: "Menu"
}
</script>

<style scoped>
.logo {
  height: 32px;
  //background: rgba(255, 255, 255, 0.2);
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-img {
  height: 32px;
}

.logo-words {
  font-size: 20px;
  color: white;
  margin-left: 10px;
}
</style>

<style>


.ant-layout-sider-collapsed .logo-words {
  display: none;
}
</style>
