<template>
  <a-layout class="main-page">
    <a-layout-sider v-if="showMenu" v-model="collapsed" :trigger="null" collapsible>
      <Menu />
    </a-layout-sider>
    <a-layout>
      <a-layout-header style="
          background: #fff;
          padding: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
      ">
        <a-icon
            class="trigger sm-hide"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
        />
        <a-dropdown placement="bottomLeft">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon
                class="trigger sm-show"
                type="menu"
            />
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0">
              <a href="/">UPS</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <a-icon
            class="trigger"
            type="logout"
            @click="logout"
        />
      </a-layout-header>
      <a-layout-content
          :style="{ margin: '16px', padding: '12px', minHeight: '280px' }"
      >
<!--        <a-layout-content-->
<!--            :style="{ margin: '24px 16px', padding: '24px', background: '#fff', minHeight: '280px' }"-->
<!--        >-->
        <a-row :gutter="[16, 20]">
          <a-col class="gutter-row" :lg="24" :xl="12">
            <div class="gutter-box">
              <UpsBaseCard :data="statusData" />
            </div>
          </a-col>
          <a-col class="gutter-row" :lg="24" :xl="12">
            <div class="gutter-box">
              <VoltCard />
            </div>
          </a-col>
        </a-row>
        <div class="space" />
        <a-row :gutter="[16, 20]">
          <a-col class="gutter-row" :lg="24" :xl="12">
            <div class="gutter-box">
              <PowerCard />
            </div>
          </a-col>
          <a-col class="gutter-row" :lg="24" :xl="12">
            <div class="gutter-box">
              <TempCard />
            </div>
          </a-col>
        </a-row>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
import Menu from "@/components/Menu";
import UpsBaseCard from "@/components/UpsBaseCard";
import PowerCard from "@/components/PowerCard";
import TempCard from "@/components/TempCard";
import VoltCard from "@/components/VoltCard";
import {getStatusData, verifyResponse} from "@/api/network";
import {LOGIN_STORAGE_KEY} from "@/consts";

const UPDATE_TIME = 5000

export default {
  name: 'MainPage',
  components: {VoltCard, TempCard, PowerCard, Menu, UpsBaseCard},
  data() {
    return {
      collapsed: false,
      statusData: {},
      intervalHandler: null,
      showMenu: true,
    };
  },
  props: { id: String },
  mounted() {
    this.updateStatusData()
    this.intervalHandler = setInterval(() => {
      this.updateStatusData()
    }, UPDATE_TIME)
    this.onResize()
    window.addEventListener(
        "resize",() => {
          this.onResize()
        },false
    );
  },
  methods: {
    async updateStatusData() {
      const res = await getStatusData({packId: this.id})
      this.statusData = verifyResponse(res)
    },
    onResize() {
      this.showMenu = window.innerWidth >= 768
    },
    logout() {
      localStorage.setItem(LOGIN_STORAGE_KEY, '')
      window.location.reload()
    },
  }
};
</script>
<style>
.main-page.ant-layout {
  height: 100%;
  min-height: 100vh;
  background-color: #f0f2f5;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.space {
  height: 20px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .sm-hide {
    display: none!important;
  }
}

@media screen and (min-width: 768px) {
  .sm-show {
    display: none!important;;
  }
}
</style>
