<template>
  <a-card
      title="功率"
  >
    <div slot="extra">
      <DateSelection @change="handleDateChanged" />
    </div>
    <LineGraph
        class="graph"
        title="功率"
        :main-color="'#ff8123'"
        :graph-data="graphData"
    />
  </a-card>
</template>

<script>
import LineGraph from "@/components/LineGraph";
import DateSelection from "@/components/DateSelection";
import {loadHistoryMixinFactory} from "@/mixins/load-history-mixin";
export default {
  name: "PowerCard",
  mixins: [loadHistoryMixinFactory('power')],
  components: {DateSelection, LineGraph},
}
</script>

<style scoped>
.graph {
  height: 200px;
}
</style>
